import React from 'react';

import { cn } from '@/lib/utils';

import { HoverCard, HoverCardContent, HoverCardTrigger } from './hover-card';

type Root = React.ForwardRefExoticComponent<HoverMenuBarProps & React.ComponentPropsWithRef<'div'>>;
type Item = React.ForwardRefExoticComponent<HoverMenubarItemProps & React.ComponentPropsWithRef<'div'>>;

const HoverMenuBar = React.forwardRef<
  React.ElementRef<Root>,
  React.ComponentPropsWithoutRef<Root>
>(({ className, ...props }, ref) => (
  <div {...props} className={cn('flex gap-[30px]', className)} ref={ref} />
));

HoverMenuBar.displayName = 'HoverMenuBar';

export type HoverMenuBarProps = {

} & React.RefAttributes<HTMLDivElement>;

const HoverMenubarTrigger = HoverCardTrigger;

HoverMenubarTrigger.displayName = 'HoverMenubarTrigger';

const HoverMenu = HoverCard;

HoverMenu.displayName = 'HoverMenu';

const HoverMenubarContent = HoverCardContent;

HoverMenubarContent.displayName = 'HoverMenubarContent';

const HoverMenubarItem = React.forwardRef<
  React.ElementRef<Item>,
  React.ComponentPropsWithoutRef<Item>
>(({ className, ...props }, ref) => (
  <div {...props} className={cn('block px-1 py-3 cursor-pointer box-border hover:bg-gray-50', className)} ref={ref} />
));

HoverMenubarItem.displayName = 'HoverMenubarItem';

export type HoverMenubarItemProps = {} & React.RefAttributes<HTMLDivElement>;

export { HoverMenuBar, HoverMenubarTrigger, HoverMenu, HoverMenubarContent, HoverMenubarItem };
