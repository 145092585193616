import { localKey } from '@/stores/local';

let token = '';

export const getToken = () => {
  if (token) {
    return token;
  }
  const tokenStr = localStorage.getItem(localKey.token);
  if (tokenStr) {
    token = tokenStr;
    return tokenStr;
  }
  return '';
};

export const setToken = (tokenStr: string) => {
  token = tokenStr;
  localStorage.setItem(localKey.token, token ?? '');
};
