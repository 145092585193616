import { httpPost, judgeIsSuccess } from '@yimoko/store';

export const localKey = {
  clientID: 'clientID',
  userID: 'userID',
  site: 'site',
  token: 'token',
};

let clientID = '';
export const getClientID = async () => {
  if (clientID) {
    return clientID;
  }

  const id = localStorage.getItem(localKey.clientID);

  if (id) {
    clientID = id;
    return id;
  }

  const res = await getFetchClientID();
  if (res) {
    clientID = res;
    localStorage.setItem(localKey.clientID, res);
  }
  return clientID;
};

// 同步获取 ClientID
export const getClientIDSync = () => {
  if (clientID) {
    return clientID;
  }
  const id = localStorage.getItem(localKey.clientID);
  if (id) {
    clientID = id;
    return id;
  }
  getClientID();
  return '';
};

// 删除 ClientID
export const removeClientID = () => {
  localStorage.removeItem(localKey.clientID);
  clientID = '';
};


// 懒式单例远程获取 ClientID
let fetchClientID: Promise<string> | null = null;
const getFetchClientID = () => {
  if (!fetchClientID) {
    fetchClientID = new Promise<string>((resolve) => {
      // TODO 调整 IP
      httpPost('/service/user/bff/id/create').then(res => resolve(judgeIsSuccess(res) ? res.data : ''));
    });
  }
  return fetchClientID;
};
