import { debounce } from 'lodash-es';
import { ChevronDown, ChevronUp, Search } from 'lucide-react';

import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useMount } from 'react-use';

import { Button } from '@/components/ui/button';
import { HoverMenu, HoverMenuBar, HoverMenubarContent, HoverMenubarItem, HoverMenubarTrigger } from '@/components/ui/hover-menubar';
import { CosImage } from '@/components/ui/image';
import { Input } from '@/components/ui/input';
import { Visible } from '@/components/ui/visible';
import { useHeaderHeight, useWindowScrollChange } from '@/hooks/window';
import { useLocale, useSupportedLocales } from '@/i18n';

import { cn } from '@/lib/utils';
import { useRouter, usePathname, Link } from '@/navigation';
const bottomNavs: {
  trigger: string,
  href?: string,
  items: { title: string; href: string; }[]
}[] = [
    {
      trigger: 'smart education platform',
      href: '/smart-education-platform',
      items: [],
    },
    {
      trigger: 'knowledge and value',
      items: [
        // {
        //   title: 'database',
        //   href: '/knowledge-and-value/database/list',
        // },
        {
          title: 'international conference and activities',
          href: '/knowledge-and-value/international-conference-and-activities/list',
        },
      ],
    },
    {
      trigger: 'partners',
      href: '/partners/home',
      items: [],
      // items: [
      //   {
      //     title: 'higher education institutions and international organizations',
      //     href: '/partners/higher-eiaio',
      //   },
      //   {
      //     title: 'corporate partners',
      //     href: '/partners/corporate-partners',
      //   },
      // ],
    },
    {
      trigger: 'about us',
      href: '/about-us',
      items: [],
    },
  ];

// 用于保存useState的值，并且可以通过ref获取到最新的值
function useStateWithRef<S = undefined>(): [S | undefined, Dispatch<SetStateAction<S | undefined>>, MutableRefObject<S | undefined>];
function useStateWithRef<S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>, MutableRefObject<S>];
function useStateWithRef<S>(initialState?: S | (() => S)) {
  const [value, setValue] = useState(initialState);
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return [value, setValue, ref];
}

export const Header = () => {
  const supportedLocales = useSupportedLocales();
  const topNas: {
    trigger: string;
    items: { title: string; href: string; }[];
    href?: string;
  }[] = [
      {
        trigger: 'news center',
        href: '/news-center/home',
        items: [],
      },
      {
        trigger: 'locale',
        items: supportedLocales,
      },
    ];
  // 控制搜索框的显示与隐藏
  const [searchVisible, setSearchVisible, searchVisibleRef] = useStateWithRef(false);
  const [keyword, setKeyword] = useState('');
  // 获取当前路由
  const pathname = usePathname();
  const { t, i18n } = useTranslation('Header');
  const router = useRouter();
  // 获取当前语言
  const locale = useLocale();
  const navLocales = topNas.find(nav => nav.trigger === 'locale')?.items ?? [];
  useWindowScrollChange(() => {
    // 获取滚动的高度
    const scrollHeight = window.scrollY ?? document.documentElement.scrollTop;
    // 获取header的高度
    const headerHeight = document.querySelector('header')?.offsetHeight ?? 0;
    if (scrollHeight > headerHeight) {
      document.querySelector('header')?.classList.add('animate__fadeHeaderBgIn');
    } else {
      document.querySelector('header')?.classList.remove('animate__fadeHeaderBgIn');
    }
  });
  useMount(() => {
    const subscribeCallback = (e: any) => {
      // 判断点击的是否是search-icon
      const searchIcon = document.querySelector('#search-icon');
      if (searchVisibleRef?.current && searchIcon?.contains(e?.target)) {
        return;
      }
      // 判断用户点击的是否是搜索框
      if (searchVisibleRef?.current && !document.querySelector('#search-box')?.contains(e?.target)) {
        setSearchVisible(false);
      }
    };
    document.addEventListener('click', subscribeCallback);
    return () => {
      document.removeEventListener('click', subscribeCallback);
    };
  });

  const [topNavOpens, setTopNavOpens] = useState(topNas.map(() => false));
  const [bottomNavsOpens, setBottomNavsOpens] = useState(bottomNavs.map(() => false));
  const navTriggerClass = 'flex items-center text-[15px] cursor-pointer border border-solid border-transparent hover:border-b-[#fff] box-border block whitespace-nowrap';
  const headerHeight = useHeaderHeight();
  return (
    <header className={cn(
      'bg-[transparent] hover:bg-[#0077D4] sticky top-0 left-0 right-0 animate__animated z-10 flex flex-col items-center justify-center',
      searchVisible && 'bg-[#0077D4]',
    )} id='header' style={{
      marginTop: `-${headerHeight}px`,
    }}>
      <div className="py-[17px] text-white flex" id='header-content'>
        <div className="flex items-center mr-[89px] cursor-pointer" onClick={() => {
          router.push('/');
        }}>
          <CosImage
            src="/global/header logo@2x.png" // 替换为你的logo路径
            alt="logo"
            width={121}
            height={96}
            className="mr-[31px] w-[121px] h-[96px]"
          />
          <h1 className="text-[17px]">{t('title')}</h1>
        </div>
        <nav className="flex flex-col items-end">
          <HoverMenuBar className="bg-transparent hover:bg-transparent mb-[20px]">
            {
              topNas.map((nav, key) => (
                <HoverMenu key={key} openDelay={200} closeDelay={100} open={topNavOpens[key]} onOpenChange={(v) => {
                  setTopNavOpens(topNavOpens.map((_, i) => (i === key ? v : false)));
                }}>
                  {nav.href ? <Link className={navTriggerClass} href={nav.href}>{t(nav.trigger)}</Link>
                    : <HoverMenubarTrigger className={navTriggerClass}>
                      {nav.trigger === 'locale' ? navLocales?.find(item => item.href === locale)?.title : t(nav.trigger)}
                      <Visible visible={!!nav.items?.length}><ChevronDown width={15} height={15} className="ml-[8px]" /></Visible>
                    </HoverMenubarTrigger>}
                  <Visible visible={!!nav.items?.length}>
                    <HoverMenubarContent className="p-0">
                      <ul className="grid">
                        {nav.items?.map(item => (
                          <HoverMenubarItem
                            key={item.title}
                            className={cn(
                              'text-center items-center justify-center block flex-1 text-[15px]',
                              nav.trigger === 'locale' && locale === item.href && 'text-[#0077D4]',
                            )}
                            onClick={() => {
                              setTopNavOpens(topNavOpens.map(() => false));
                              if (nav.trigger === 'locale') {
                                if (locale === item.href) return;
                                document.documentElement.lang = item.href;
                                i18n.changeLanguage(item.href);
                              }
                            }}
                          >
                            {item.title}
                          </HoverMenubarItem>
                        ))}
                      </ul>
                    </HoverMenubarContent>
                  </Visible>
                </HoverMenu>
              ))
            }
          </HoverMenuBar>
          <div className="flex items-center justify-center">
            <HoverMenuBar className="">
              {
                bottomNavs.map((nav, key) => (
                  <HoverMenu key={key} openDelay={200} closeDelay={100} open={bottomNavsOpens[key]} onOpenChange={(v) => {
                    setBottomNavsOpens(bottomNavsOpens.map((_, i) => (i === key ? v : false)));
                  }}>
                    {nav.href ? <Link className={navTriggerClass} href={nav.href}>{t(nav.trigger)}</Link>
                      : <HoverMenubarTrigger className={navTriggerClass}>
                        {t(nav.trigger)}
                        <Visible visible={!!nav.items?.length}><ChevronDown width={15} height={15} className="ml-[8px]" /></Visible>
                      </HoverMenubarTrigger>}
                    {nav.items?.length ? <HoverMenubarContent className="p-0">
                      <ul className="grid">
                        {nav.items?.map(item => (
                          <HoverMenubarItem
                            key={item.title}
                            className={cn('text-center items-center justify-center block flex-1 text-[15px]', pathname === item.href && 'text-[#0077D4]')}
                            onClick={() => {
                              setBottomNavsOpens(bottomNavsOpens.map(() => false));
                              if (pathname === item.href) return;
                              router.push(item.href);
                            }}
                          >
                            {t(item.title)}
                          </HoverMenubarItem>
                        ))}
                      </ul>
                    </HoverMenubarContent> : null}
                  </HoverMenu>
                ))
              }
            </HoverMenuBar>
            <div className="flex items-center cursor-default hover:border-b-[1px] ml-[10px]" id='search-icon' onClick={debounce(() => setSearchVisible(!searchVisible), 300, { leading: true })}>
              <Search className="w-[18px] h-[18px]" />
            </div>
          </div>
        </nav>
      </div>
      <Visible visible={searchVisible}>
        <search className="flex items-center justify-center w-full border-t-[#fff] border-t-[1px] h-[150px] relative" id='search-box'>
          <div className="flex items-center justify-center border-b-[2px] border-b-[#fff] py-[7px]">
            <CosImage src="/home/icon_search@2x.png" alt="Search" width={36} height={36} className="w-[36px] h-[36px]" />
            <Input placeholder={t('search placeholder')} className="w-[220px] border-none outline-none bg-transparent focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 placeholder:text-[#fff] text-[#fff] text-base" value={keyword} onChange={e => setKeyword(e?.target?.value)} />
            <Button className="bg-transparent rounded-[2px] text-white text-base border-[1px] border-white w-[90px] h-[38px]" variant='outline' onClick={() => {
              if (!keyword) return;
              setSearchVisible(false);
              router.push(`/search?titleLike=${keyword}`);
            }} disabled={!keyword}>{t('search')}</Button>
          </div>
          <ChevronUp className="absolute bottom-0 left-0 right-0 m-auto text-white cursor-pointer w-[18px] h-[18px]" onClick={debounce(() => setSearchVisible(false), 300)}>关闭</ChevronUp>
        </search>
      </Visible>
    </header >
  );
};
