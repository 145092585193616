
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';

export const useWindowScrollChange = (callback: (e: Event) => void) => {
  useMount(() => {
    window.addEventListener('scroll', callback, {
      capture: false,
      passive: true,
    });
    return () => {
      window.removeEventListener('scroll', callback);
    };
  });
};

export const useHeaderHeight = () => {
  const { i18n } = useTranslation();
  const getHeight = () => document.getElementById('header')?.offsetHeight ?? 98;
  const [headerHeight, setHeaderHeight] = useState(getHeight());
  useMount(() => {
    const height = getHeight();
    if (height === headerHeight) return;
    setHeaderHeight(height);
  });
  useEffect(() => {
    const height = getHeight();
    if (height === headerHeight) return;
    setHeaderHeight(height);
  }, [headerHeight, i18n.language]);
  return headerHeight;
};


export const useFooterHeight = () => {
  const { i18n } = useTranslation();
  const getHeight = () => document.getElementById('footer')?.offsetHeight ?? 316;
  const [footerHeight, setFooterHeight] = useState(getHeight());
  useMount(() => {
    const height = getHeight();
    if (height === footerHeight) return;
    setFooterHeight(height);
  });
  useEffect(() => {
    const height = getHeight();
    if (height === footerHeight) return;
    setFooterHeight(height);
  }, [footerHeight, i18n.language]);
  return footerHeight;
};

export const useHeaderWidth = () => {
  const { i18n } = useTranslation();
  const getWidth = () => document.getElementById('header-content')?.offsetWidth ?? 1050;
  const [headerWidth, setHeaderWidth] = useState(getWidth());
  useMount(() => {
    const width = getWidth();
    if (width === headerWidth) return;
    setHeaderWidth(width);
  });
  useEffect(() => {
    const width = getWidth();
    if (width === headerWidth) return;
    setHeaderWidth(width);
  }, [headerWidth, i18n.language]);
  return headerWidth;
};

export const useHeaderSize = () => {
  const height = useHeaderHeight();
  const width = useHeaderWidth();
  return { height, width };
};
