/* eslint-disable max-len */
import { observer } from '@formily/react';
import { ConfigStore, IHTTPCode, http, useRoot, useUser, httpRequest, useConfig as useConfigOld, INotifier } from '@yimoko/store';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { components } from '@/components/index';
import { getClientIDSync } from '@/stores/local';
import { getToken } from '@/stores/token';

import { translationLocaleData } from '@/utils/intl';

import { useRouter } from './use-router';

// const apiHost = process.env.NODE_ENV === 'production' ? '/api' : 'https://console.test.lebanxueyuan.com/api';
const apiHost = process.env.REACT_APP_BACKEND_API_URL;

http.defaults.baseURL = apiHost;

export const InitConfig = observer(({ children }: { children: React.ReactNode }) => {
  const nav = useNavigate();
  const root = useRoot();
  const user = useUser();
  const id = user?.id;
  const [isInit, setIsInit] = useState(false);
  const { i18n } = useTranslation();
  useEffect(() => {
    http.defaults.headers.common.Accept = 'application/json';
    http.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
    http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    http.defaults.headers.common['x-md-global-channel'] = 'web';
    http.defaults.headers.common['x-md-global-use-type'] = 'client';
    http.defaults.headers.common['x-md-global-platform'] = navigator.platform;
    http.defaults.headers.common['x-md-global-sign'] = '';

    http.interceptors.request.use((config) => {
      const newConfig = config;
      // 当其为空时 Content-Type 则设置也不会生效 为 null
      !newConfig.data && (newConfig.data = {});
      newConfig.headers.Authorization = getToken();
      newConfig.headers['x-md-global-platform'] = navigator.platform;
      newConfig.headers['x-md-global-client-id'] = getClientIDSync();
      newConfig.headers['x-md-global-tenantID'] = '10001';
      // newConfig.headers['x-md-global-wechat-offiaccount-appid'] = '';
      // newConfig.headers['x-md-global-wechat-offiaccount-openid'] = '';
      // newConfig.headers['x-md-global-wechat-platform-appid'] = '';
      // newConfig.headers['x-md-global-wechat-platform-openid'] = '';
      // newConfig.headers['x-md-global-wechat-work-appid'] = '';
      // newConfig.headers['x-md-global-wechat-work-openid'] = '';
      newConfig.headers['Accept-Language'] = i18n.language;
      return newConfig;
    });

    http.interceptors.response.use(
      // eslint-disable-next-line complexity
      (response) => {
        const newResponse = response;
        if (newResponse.status >= 200 && newResponse.status < 300) {
          const { data } = newResponse;
          if (data) {
            const keys = Object.keys(data);
            if (keys.length === 1) {
              // pb 会将 value 包一层
              if (keys[0] === 'value') {
                newResponse.data = data.value;
              }
              if (keys[0] === 'data') {
                newResponse.data = data.data;
              }
            }
          }
          newResponse.data = translationLocaleData(newResponse.data);
        }
        // 如果请求的是json却返回了html，可能是网关的路由问题，当成一个错误处理
        if (typeof newResponse?.config?.headers['Content-Type'] === 'string') {
          if (newResponse?.config?.headers['Content-Type']?.includes('application/json') && newResponse.headers['content-type']?.includes('text/html')) {
            return Promise.reject(new Error('html'));
          }
        }

        return newResponse;
      },
      // eslint-disable-next-line complexity
      (err) => {
        const { response } = err;
        if (response) {
          const { data } = response;
          const { href, origin } = window.location;
          const redirect = encodeURIComponent(href.replace(origin, ''));
          // 鉴权接口 直接跳转
          if (err?.config?.url === '/service/user/bff/user/info') {
            if (data?.code === IHTTPCode.unauthorized) {
              nav(`/user/login?redirect=${redirect}`, { replace: true });
            }
            if (data?.code === IHTTPCode.forbidden && data.metadata.isChangePassword) {
              nav(`/user/password/change?redirect=${redirect}`, { replace: true });
            }
          } else {
            if (data?.code === IHTTPCode.unauthorized) {
              // TODO 弹窗
              nav(`/user/login?redirect=${redirect}`, { replace: true });
            }
            if (data?.code === IHTTPCode.forbidden && data.metadata.isChangePassword) {
              // TODO 弹窗
              nav(`/user/password/change?redirect=${redirect}`, { replace: true });
            }
          }
        }
        return Promise.reject(err);
      },
    );

    setIsInit(true);
  }, [i18n.language, nav]);

  useEffect(() => {
    // 获取用户权限
    if (id) {
    }
  }, [id, root]);

  return <>{isInit ? children : ''}</>;
});


export const defaultConfig = {
  versionKey: '',
  apiHost: '',
  uploadAPI: '',
  indexPage: '',
  pageCachePrefix: '',
};

export type IConfig = typeof defaultConfig;

// TODO 配置通知
// eslint-disable-next-line complexity
export const notifier: INotifier = (type, msg, options) => {
  if (type === 'close') {
    // notification.destroy(msg ?? options?.key);
    return;
  }
  if (type === 'config') {
    // options && notification.config(options);
    return;
  }
  console.log(type, msg, options);
  // if (judgeValidKey(type, notification)) {
  //   const fn = notification[type] as Function;
  //   if (typeof fn === 'function') {
  //     fn({ message: titleMap[type], ...options, description: msg || options?.description });
  //   };
  // }
};

export const configStore: ConfigStore<typeof defaultConfig> = new ConfigStore(
  defaultConfig,
  {
    components,
    notifier,
    apiExecutor: httpRequest,
    useRouter,
  },
);

export const useConfig = () => useConfigOld<IConfig>();

export const { logger } = configStore;


