import { Footer } from './footer';
import { Header } from './header';

export const BaseLayout = ((props: any) => {
  const { children } = props;

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
});
