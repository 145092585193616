import qs from 'qs';
import { NavigateOptions, Link as RLink, LinkProps as RLinkProps, To, useLocation, useNavigate } from 'react-router-dom';

export type LinkProps = XOR<RLinkProps & { href?: string }, 'to', 'href'>;
export const Link = (props: LinkProps) => {
  const { href, to, ...rest } = props;
  const _to = to ?? href;
  if (!_to) {
    return <a {...rest}>{props.children}</a>;
  }
  if (href?.startsWith('http') || (typeof to === 'string' && to?.startsWith('http'))) {
    // @ts-expect-error
    return <a href={_to} {...rest}>{props.children}</a>;
  }
  return <RLink to={_to} {...rest} />;
};

export const usePathname = () => {
  const { pathname } = useLocation();
  return pathname!;
};

export const useRouter = () => {
  const navigate = useNavigate();
  return {
    push: (to: To, options?: NavigateOptions) => navigate(to, options),
    replace: (to: To, options?: NavigateOptions) => navigate(to, { replace: true, ...options }),
  };
};

export const useSearchParams = <T extends Record<string, any>>() => {
  const { search } = useLocation();
  return qs.parse(search, { ignoreQueryPrefix: true }) as T;
};

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };

type XOR<T, A extends keyof T, B extends keyof T> = (Without<Omit<T, A>, Omit<T, B>> & Omit<T, B>) | (Without<Omit<T, B>, Omit<T, A>> & Omit<T, A>);

