import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';

import App from './app';
import './index.css';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<BrowserRouter><App /></BrowserRouter>);

// // 更新时间间隔 1 小时
// const updateInterval = 60 * 60 * 1000;
// // 更新时间
// const updateDate = new Date().getTime();
// // 是否已监听
// const isListening = false;
// // 是否检查更新，当用户忽略更新后不再检查
// const isCheckUpdate = true;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register({
//   onRegister: (registration) => {
//     if (!isListening) {
//       isListening = true;
//       document.addEventListener('visibilitychange', () => {
//         if (document.visibilityState === 'visible') {
//           const now = new Date().getTime();
//           if (now - updateDate > updateInterval && isCheckUpdate) {
//             updateDate = now;
//             console.log('检查更新');
//             registration?.update?.();
//           }
//         }
//       });
//     }
//   },
//   onUpdate: (registration) => {
//     isCheckUpdate = false;
//     const { waiting } = registration;
//     waiting?.postMessage({ type: 'SKIP_WAITING' });
//     console.log('更新');
//     globalThis.location.reload();
//   },
//   onActivate: (registration) => {
//     const { waiting } = registration;
//     waiting?.postMessage({ type: 'SKIP_WAITING' });
//     console.log('激活', registration);
//     globalThis.location.reload();
//   },
// });
