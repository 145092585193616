
export type VisibleProps = {
  children: React.ReactNode
  visible: boolean
};

export const Visible = (props: VisibleProps) => {
  const { children, visible } = props;
  return visible ? <>{children}</> : null;
};
