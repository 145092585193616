import { useState } from 'react';

import { cn } from '@/lib/utils';
const COS_PATH = process.env.REACT_APP_COS_PATH ?? '';

type ImageProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
// eslint-disable-next-line max-len
const fallbackSrc = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAABC9JREFUWEfFl09oHHUUx7/vt5tNNxWrklv1UOLBok0FiyFFhE2TmVTRoOxONl4Ue1BoOtttxVSQBBUtQhOzsUWqRm9td7KIEoo72zbBS5CKkByq9VBTMDkUbLEkuM2f+T2ZjZNM0t3MTHLInnaX93vfz3u/75vf/Ahb/KEt1scyQN/5wj4Rxq6NAvGi9Uu64+DNoOtLAANG4Q0Gfxt08ap45jOp9tbOoDlKAJmseR6E7SlNfTloggHDjDNzlii851/wdBR8DeCdHnkmJejVtKaM/w+QN+wFqfZWLQgAM1NmqDAhgN90TU0OGIVuBh+FEG8zM1fKRcB7kBhPtStvrgvwhXFl5xysZwg8ndLUX9cmdFdfDZ6eg5wEqF/XlA/XKySTXSm4IkDGKBxjyJMEitjJCHSxOFPs6DrUNmP/rlR9EWLXCa3l7qYAMtl8DERXAHqH5he/RnVkL7NlgPFdql09vGTcpb23QqjfLkNTfqtf8pxHB/qz5pcE7Em1q41OJZ/nLqWl5O6Upjy8mep9AwjCU7qm7i8HUKoenLUE6udlaCoKOUk+9t7J5dmB9bZA15ROt/NPG5cbLcgLRVC91977Bii1qYIJa3bUKFJahl39sXjrtSBjGwjADi43hhnDHCeB63pcTW5E3JcH1h0ho3CVgBldUw5sCUB/zmwgiTGB0GtHtOZsOYgzxugDi7TwDZgbiOgTPaGcdcd5mtCrssxQ4SswHwwj8sRhLTbrjrfFFzCfJ6AOjCES1CmJjx+Nq58F9kBPT494aHfj03MidMPt8FPnhmurwpE/CGJQ11redRKviHOdlKGmdLLl9/6smRaCet0QvjrQZ4xFQ5j5HoACxi3iKlVPNk04YgNGvpOZ+oit3XryhRtucSGqY0fisetObH/OTAtegfAEKInT7DAYewVRQoLft7+TlM16snWitMdY+BHgxyMI75u9t/hP1TaYBK5bK14OgiwuPWHt0/e+w8gt7gi6/2PQKwQ+aYtLKZw2P0+En0DoSCXUC5U85HSCme+AeeQ+AIsefN2pnGS42d1yB4KAJma+5YgvG8swR4DSS03DeiZehpAytxpAUBSgqLvVa93ttH2t+Klzo7VV4fmrAG6mNLXJe4rMYUgurgIgIeLMuO203a94j2FEHsGOywAe3RaNPPvWS7G/PQHKHsdETST5gG0yv+J23MCQOciMeEjw/k6fZ4PnFLjnulLbl8QLx5n5UwK36VrrRa/KAz2I3KO2ds9L4kb+RQb9QERdekLp9Ste4TBa/VruJX46l3/SkjRGhJyeUA8FES8L4OtiQvRRKqF0nx0erb1XLDl+6g7uNn+gafObBrATeF3NrEX++XiH+ldmad5jBIww8+2g4nY8AzEhxKCeUE4EvpxmjHwvGI9tRNhZQ0L8WYzUfNzV9txMYIDNCJdb+x+CEgxO2zgCnAAAAABJRU5ErkJggg==';
export const CosImage = (props: ImageProps & { cosPath?: string }) => {
  const [showFallback, setShowFallback] = useState(false);
  const { cosPath = COS_PATH, src, ...rest } = props;
  const fallBackImg = <div className={cn(
    rest?.className,
    'bg-[#F3F3F3] items-center justify-center flex',
  )}>
    <img src={fallbackSrc} width={24} height={24} alt="fallback" className="max-w-[80%]" />
  </div>;
  if (!src) {
    return fallBackImg;
  }
  // eslint-disable-next-line no-nested-ternary
  const _src = /^http/.test(src) ? src : (cosPath ? `${cosPath}${src}` : src);
  return showFallback ? fallBackImg : (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img src={_src} onError={(e) => {
      console.error('Image load error:', e, _src);
      setShowFallback(true);
    }} {...rest} />
  );
};
