
import { IUseRouter } from '@yimoko/store';
import { omit } from 'lodash-es';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

export const useRouter: IUseRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [search] = useSearchParams();
  const obj = Object.fromEntries(search);
  const curParams = Object.assign({}, omit(params, '*'), obj);

  return { navigate, location, params: curParams };
};
