import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const IndexPage = lazy(() => import('@/pages/index').then(module => ({ default: module.IndexPage })));
const AboutUs = lazy(() => import('@/pages/about-us').then(module => ({ default: module.AboutUs })));
const DatabaseDetail = lazy(() => import('@/pages/knowledge-and-value/database/detail').then(module => ({ default: module.DatabaseDetail })));
const DatabaseList = lazy(() => import('@/pages/knowledge-and-value/database/list').then(module => ({ default: module.DatabaseList })));
const InternationalConferenceAndActivitiesDetail = lazy(() => import('@/pages/knowledge-and-value/international-conference-and-activities/detail').then(module => ({ default: module.InternationalConferenceAndActivitiesDetail })));
const InternationalConferenceAndActivitiesList = lazy(() => import('@/pages/knowledge-and-value/international-conference-and-activities/list').then(module => ({ default: module.InternationalConferenceAndActivitiesList })));
const NewsCenter = lazy(() => import('@/pages/news-center/home').then(module => ({ default: module.NewsCenter })));
const NewsCenterDetail = lazy(() => import('@/pages/news-center/detail').then(module => ({ default: module.NewsCenterDetail })));
const SearchPage = lazy(() => import('@/pages/search').then(module => ({ default: module.SearchPage })));
const SmartEducationPlatform = lazy(() => import('@/pages/smart-education-platform').then(module => ({ default: module.SmartEducationPlatform })));
const ErrorNotFound = lazy(() => import('./components/ui/status').then(module => ({ default: module.ErrorNotFound })));
// const CorporatePartners = lazy(() => import('@/pages/partners/corporate-partners').then(module => ({ default: module.CorporatePartners })));
// const HigherEiaio = lazy(() => import('@/pages/partners/higher-eiaio').then(module => ({ default: module.HigherEiaio })));
const Partners = lazy(() => import('@/pages/partners/home').then(module => ({ default: module.Partners })));

export const RootRouter = () => (
  <Routes>
    {/* <Route path="/user/*" element={<UserRouter />} /> */}
    <Route index element={<IndexPage />} />
    <Route path='/knowledge-and-value/international-conference-and-activities/list' element={<InternationalConferenceAndActivitiesList />} />
    <Route path='/knowledge-and-value/international-conference-and-activities/detail' element={<InternationalConferenceAndActivitiesDetail />} />
    <Route path='/knowledge-and-value/database/list' element={<DatabaseList />} />
    <Route path='/knowledge-and-value/database/detail' element={<DatabaseDetail />} />
    {/* <Route path='/partners/higher-eiaio' element={<HigherEiaio />} />
    <Route path='/partners/corporate-partners' element={<CorporatePartners />} /> */}
    <Route path='/partners/home' element={<Partners />} />
    <Route path='/about-us' element={<AboutUs />} />
    <Route path='/news-center/home' element={<NewsCenter />} />
    <Route path='/news-center/detail' element={<NewsCenterDetail />} />
    <Route path='/search' element={<SearchPage />} />
    <Route path='/smart-education-platform' element={<SmartEducationPlatform />} />

    <Route path='*' element={<ErrorNotFound />} />
  </Routes>
);

// const NeedAuthRoutes = () => (
//   (<>
//     <Routes>

//     </Routes>
//   </>)
// );

// const AuthRouter = observer(() => {
//   const root = useRoot();
//   const nav = useNavigate();
//   const authStore = useOperateStore({
//     api: { url: '/service/user/bff/user/info' },
//     runAfter: {
//       runOnSuccess: (res) => {
//         const id = res.data?.user?.id;
//         if (id) {
//           root.setUser(res.data?.user);
//           if (!res.data?.user?.phone) {
//             // root.setDataItem(BIND_PHONE_MODEL_KEY, true);
//           }
//         } else {
//           nav('/user/login');
//         }
//       },
//       notify: false,
//     },
//   });

//   useEffect(() => {
//     authStore.runAPI();
//   }, [authStore]);

//   const { loading, response } = authStore;

//   if (judgeIsUnauthorized(response)) {
//     return <Navigate to="/user/login" />;
//   }

//   if (loading || !judgeIsSuccess(response)) {
//     return (
//       <Spin spinning={loading} tip="登录中……" >
//         <div style={{ minHeight: 400 }}>
//           <StorePageContent store={authStore} skeleton={false} />
//         </div>
//       </Spin>
//     );
//   }

//   return <NeedAuthRoutes />;
// });


