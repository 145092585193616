import { isObject } from 'lodash-es';

function isTypeArray<T extends any[]>(value: any): value is T {
  return Array.isArray(value);
}

function isTypeObject<T extends Record<string, any>>(value: any): value is T {
  return isObject(value);
}

// 遍历data数组把所有的字段优先获取`${key}Local'字段, 如果没有则获取`${key}`字段
export function translationLocaleData<T extends Record<string, any> | any[]>(input: T): T {
  if (isTypeArray(input)) {
    return input.map(item => translationLocaleData(item)) as T;
  }
  if (isTypeObject(input)) {
    const keys = Object.keys(input);
    const newObject = Object.assign({}, input as Record<string, any>);
    keys.forEach((key) => {
      const localKey = `${key}Local`;
      if (typeof newObject[localKey] === 'string' && newObject[localKey]) {
        if (input[localKey] === '<p><br></p>') {
          newObject[key] = input[key];
          return;
        }
        newObject[key] = input[localKey] ?? input[key];
      } else if (isTypeObject(input[key])) {
        newObject[key] = translationLocaleData(input[key]);
      }
    });
    return newObject as T;
  }
  return input;
};
